var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'card--flex-toolbar-xs': _vm.$vuetify.breakpoint.xsOnly,
    'card--flex-toolbar': _vm.$vuetify.breakpoint.smAndUp
  }},[_c('v-toolbar',{staticClass:"elevation-3",attrs:{"flat":"","dense":"","color":"secondary"}},[_c('v-toolbar-title',{staticClass:"body-2 white--text"},[_vm._v(_vm._s(this.$route.meta.subtitle))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("autorenew")])],1)],1),_c('v-card-text',{class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-progress-circular',{class:{
        'loading-visible': this.loading,
        'loading-hidden': !this.loading
      },attrs:{"size":50,"color":"secondary","indeterminate":""}}),_c('v-container',{staticClass:"pt-0",class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"2","xl":"1"}},[_c('v-select',{attrs:{"items":_vm.yearList},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"4"}},[_c('InfoPanel',{attrs:{"title":"Iznos plaćenih članarina","info":_vm.stats.paid_membership_amount.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                '.'
              ) +
                (_vm.year>=2023 ? ' EUR / ' : ' HRK / ') +
                _vm.stats.total_membership_amount.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  '.'
                ) +
                (_vm.year>=2023 ? ' EUR' : ' HRK'),"color":"secondary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"4"}},[_c('InfoPanel',{attrs:{"title":"Broj članova za koje plaća poduzeće","info":_vm.stats.number_of_companypays,"color":"info"}})],1)],1),_c('v-row',[_vm._l((_vm.stats.ind_paid_membership_by_amount),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":'Plaćene članarine '+item.price,"info":item.amount,"color":"primary"}})],1)}),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Plaćene članarine - žene","info":_vm.stats.individual_payments_women,"color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Broj počasnih članova","info":_vm.stats.board_members,"color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Broj članova studenata","info":_vm.stats.student_members,"color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Plaćene individualne članarine","info":_vm.stats.individual_payments +
                ' / ' +
                _vm.stats.total_individual_invoices,"color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Plaćene kolektivne članarine","info":_vm.stats.company_payments + ' / ' + _vm.stats.total_company_quotes,"color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Broj ažuriranih članova","info":_vm.stats.number_of_updates,"color":"info"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Broj članova","info":_vm.stats.number_of_members,"color":"info"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Broj žena članova","info":_vm.stats.women_count,"color":"info"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Broj članova mlađih od 35","info":_vm.stats.number_of_young,"color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Broj članova starijih od 65","info":_vm.stats.number_of_pensioners,"color":"error"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"E-mailova za poslati","info":_vm.stats.mails_to_send + ' (' + _vm.stats.mails_to_send_error + ')*',"color":"secondary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Iznos plaćenih međ. ind. članarina","info":_vm.stats.paid_membership_i_ind_amount.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                '.'
              ) + (_vm.year>=2023 ? ' EUR' : ' HRK'),"color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Iznos plaćenih međ. kol. članarina","info":_vm.stats.paid_membership_i_kol_amount.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                '.'
              ) + (_vm.year>=2023 ? ' EUR' : ' HRK'),"color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Broj plaćenih međ. ind. članarina","info":_vm.stats.paid_membership_i_ind_count +
                ' / ' +
                _vm.stats.total_membership_i_ind_count,"color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Broj plaćenih međ. kol. članarina","info":_vm.stats.paid_membership_i_kol_count +
                ' / ' +
                _vm.stats.total_membership_i_kol_count,"color":"info"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Iznos plaćenih kotizacija","info":_vm.stats.paid_fee_amount.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                '.'
              ) + (_vm.year>=2023 ? ' EUR' : ' HRK'),"color":"info"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('InfoPanel',{attrs:{"title":"Iznos plaćenih sponzorstava","info":_vm.stats.paid_sponsor_amount.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                '.'
              ) + (_vm.year>=2023 ? ' EUR' : ' HRK'),"color":"info"}})],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }