<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-toolbar-title class="body-2 white--text">{{
        this.$route.meta.subtitle
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small fab dark class="mx-1" color="primary" @click="reload">
        <v-icon dark>autorenew</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-progress-circular
        :size="50"
        color="secondary"
        indeterminate
        :class="{
          'loading-visible': this.loading,
          'loading-hidden': !this.loading
        }"
      ></v-progress-circular>
      <v-container
        fluid
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row dense>
          <v-col cols="12" md="4" lg="2" xl="1">
            <v-select :items="yearList" v-model="year"></v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6" lg="6" xl="4">
            <InfoPanel
              title="Iznos plaćenih članarina"
              :info="
                stats.paid_membership_amount.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  '.'
                ) +
                  (year>=2023 ? ' EUR / ' : ' HRK / ') +
                  stats.total_membership_amount.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    '.'
                  ) +
                  (year>=2023 ? ' EUR' : ' HRK')
              "
              color="secondary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="6" xl="4">
            <InfoPanel
              title="Broj članova za koje plaća poduzeće"
              :info="stats.number_of_companypays"
              color="info"
            >
            </InfoPanel>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, index) in stats.ind_paid_membership_by_amount" :key="index" cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              :title="'Plaćene članarine '+item.price"
              :info="
                item.amount
              "
              color="primary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Plaćene članarine - žene"
              :info="
                stats.individual_payments_women
              "
              color="primary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Broj počasnih članova"
              :info="
                stats.board_members
              "
              color="primary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Broj članova studenata"
              :info="
                stats.student_members
              "
              color="primary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Plaćene individualne članarine"
              :info="
                stats.individual_payments +
                  ' / ' +
                  stats.total_individual_invoices
              "
              color="primary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Plaćene kolektivne članarine"
              :info="
                stats.company_payments + ' / ' + stats.total_company_quotes
              "
              color="primary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Broj ažuriranih članova"
              :info="stats.number_of_updates"
              color="info"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Broj članova"
              :info="stats.number_of_members"
              color="info"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Broj žena članova"
              :info="stats.women_count"
              color="info"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Broj članova mlađih od 35"
              :info="stats.number_of_young"
              color="primary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Broj članova starijih od 65"
              :info="stats.number_of_pensioners"
              color="error"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="E-mailova za poslati"
              :info="
                stats.mails_to_send + ' (' + stats.mails_to_send_error + ')*'
              "
              color="secondary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Iznos plaćenih međ. ind. članarina"
              :info="
                stats.paid_membership_i_ind_amount.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  '.'
                ) + (year>=2023 ? ' EUR' : ' HRK')
              "
              color="primary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Iznos plaćenih međ. kol. članarina"
              :info="
                stats.paid_membership_i_kol_amount.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  '.'
                ) + (year>=2023 ? ' EUR' : ' HRK')
              "
              color="primary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Broj plaćenih međ. ind. članarina"
              :info="
                stats.paid_membership_i_ind_count +
                  ' / ' +
                  stats.total_membership_i_ind_count
              "
              color="primary"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Broj plaćenih međ. kol. članarina"
              :info="
                stats.paid_membership_i_kol_count +
                  ' / ' +
                  stats.total_membership_i_kol_count
              "
              color="info"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Iznos plaćenih kotizacija"
              :info="
                stats.paid_fee_amount.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  '.'
                ) + (year>=2023 ? ' EUR' : ' HRK')
              "
              color="info"
            >
            </InfoPanel>
          </v-col>
          <v-col cols="12" md="6" lg="4" xl="3">
            <InfoPanel
              title="Iznos plaćenih sponzorstava"
              :info="
                stats.paid_sponsor_amount.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  '.'
                ) + (year>=2023 ? ' EUR' : ' HRK')
              "
              color="info"
            >
            </InfoPanel>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import InfoPanel from "../components/InfoPanel";
export default {
  data() {
    return {
      loading: false,
      year: new Date().getFullYear(),
      yearList: [],
      stats: {
        individual_payments: "-",
        company_payments: "-",
        total_individual_invoices: "-",
        total_company_quotes: "-",
        number_of_members: "-",
        number_of_young: "-",
        number_of_pensioners: "-",
        number_of_companypays: "-",
        number_of_updates: "-",
        total_membership_amount: "-",
        paid_membership_amount: "-",
        paid_membership_i_ind_amount: "-",
        paid_membership_i_kol_amount: "-",
        total_membership_i_ind_count: "-",
        total_membership_i_kol_count: "-",
        paid_membership_i_ind_count: "-",
        paid_membership_i_kol_count: "-",
        paid_fee_amount: "-",
        paid_sponsor_amount: "-"
      }
    };
  },
  components: {
    InfoPanel
  },
  watch: {
    year: {
      handler() {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      this.loading = true;
      this.$http
        .get("statistics/statistics.php", {
          params: {
            year: this.year
          }
        })
        .then(response => {
          this.stats = response.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    }
  },
  mounted() {
    var y = new Date().getFullYear();
    for (var i = y; i >= 2020; i--) {
      this.yearList.push(i);
    }
    this.reload();
  }
};
</script>
