<template>
  <v-sheet
    :color="color"
    elevation="4"
    @mouseover="mouseover = true"
    @mouseout="mouseover = false"
  >
    <div
      class="text-center font-weight-bold pa-4"
      :class="{ 'info-big': mouseover, 'info-small': !mouseover }"
    >
      {{ info }}
    </div>
    <div
      class="title-div"
      :class="{ 'title-small': mouseover, 'title-big': !mouseover }"
    >
      {{ title }}
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: {
    info: String,
    title: String,
    color: String
  },
  data() {
    return {
      mouseover: false
    };
  }
};
</script>

<style scoped>
.title-div {
  color: white;
  background-color: black;
  opacity: 0.5;
  text-align: center;
}
.title-big {
  font-size: 12pt;
  transition: 400ms;
}
.title-small {
  font-size: 10pt;
  transition: 400ms;
}
.info-big {
  font-size: 30pt;
  transition: 400ms;
}
.info-small {
  font-size: 24pt;
  transition: 400ms;
}
</style>
